import Vue from "vue";
import axios from 'axios'
import { updateToken } from "@/middlewares/updateToken";

axios.interceptors.request.use((config) => {
  // Verifica se a requisição necessita de autenticação
  if (config.isAuthenticated) {
    // Atualiza o token
    updateToken(window.location.toString(), function () {
      config.headers.Authorization = `Bearer ${Vue.$keycloak.token}`;
    });
  }

  return config;
});
